import React, { useState } from 'react';
import { Select, Button, Group, Text } from '@mantine/core';
import CustomTooltip from './CustomToolTip'; // Import the CustomTooltip component
import QuoteRequestModal from './QuoteRequestModal';

const FractionalCISOForm = () => {
  const [companySize, setCompanySize] = useState('Small');
  const [customSoftwareComplexity, setCustomSoftwareComplexity] = useState('');
  const [complianceComplexity, setComplianceComplexity] = useState('');
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);

  // Placeholder for a more complex estimation logic
  const calculateEstimate = () => {
    let sizeMultiplier = 1;
    let customSoftwareMultiplier = 1;
    let complianceMultiplier = 1;

    // Adjust the multiplier based on the company size
    switch (companySize) {
      case 'Small':
        sizeMultiplier = 1;
        break;
      case 'Medium':
        sizeMultiplier = 1.25;
        break;
      case 'Large':
        sizeMultiplier = 1.5;
        break;
      default:
        sizeMultiplier = 1;
        break;
    }

    // Adjust the multiplier based on custom software complexity
    switch (customSoftwareComplexity) {
      case 'None':
        customSoftwareMultiplier = 1;
        break;
      case 'Medium':
        customSoftwareMultiplier = 1.4;
        break;
      case 'High':
        customSoftwareMultiplier = 1.8;
        break;
      default:
        sizeMultiplier = 1;
        break;
    }

    // Adjust the multiplier based on compliance complexity
    switch (complianceComplexity) {
      case 'None':
        complianceMultiplier = 1;
        break;
      case 'Some':
        complianceMultiplier = 1.5;
        break;
      case 'Multiple':
        complianceMultiplier = 2;
        break;
      default:
        sizeMultiplier = 1;
        break;
      }

    // Calculate the estimated monthly cost
    let monthlyCost = (21000 * sizeMultiplier * customSoftwareMultiplier * complianceMultiplier) / 12;
    monthlyCost = Math.ceil(monthlyCost / 100) * 100;
    setEstimatedCost(monthlyCost); 
  };

  return (
    <div style={{ marginTop: '20px', margin: '20px' }}>
      <CustomTooltip>

      <Select
        label="Company Size"
        placeholder="Select size"
        data={['Small', 'Medium', 'Large']}
        value={companySize}
        onChange={(value) => {
          setCompanySize(value);
          calculateEstimate();
        }}
      />
      </CustomTooltip>

      <Select
        label="Custom Software Complexity"
        placeholder="Select complexity"
        data={['None', 'Medium', 'High']}
        value={customSoftwareComplexity}
        onChange={(value) => {
          setCustomSoftwareComplexity(value);
          calculateEstimate();
        }}
        style={{ marginTop: '20px', marginBottom: '20px' }}
      />

      <Select
        label="Compliance Frameworks"
        placeholder="Select complexity"
        data={['None', 'Some', 'Multiple']}
        value={complianceComplexity}
        onChange={(value) => {
          setComplianceComplexity(value);
          calculateEstimate();
        }}
        style={{ marginTop: '20px', marginBottom: '20px' }}
      />

    <Group spacing="md" style={{ marginTop: '20px', margin: '5px' }}>
        <Button onClick={calculateEstimate}>Calculate</Button>
        <Button variant="outline" onClick={() => setModalOpen(true)}>Request Quote</Button>
      </Group>


        <Text size="lg" weight={500}>
        Estimated Monthly Fee: ${(.8 * estimatedCost).toLocaleString()} - ${(1.5 * estimatedCost).toLocaleString()}
        </Text>

  
      <QuoteRequestModal 
        isModalOpen={isModalOpen} 
        setModalOpen={setModalOpen} 
        formData={{ companySize, customSoftwareComplexity, complianceComplexity, estimatedCost }}
      />
    </div>
  );
};

export default FractionalCISOForm;
