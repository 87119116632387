import React, { useState } from 'react';
import { NumberInput, Select, Switch, Button, Group, Text } from '@mantine/core';
import QuoteRequestModal from './QuoteRequestModal';


const WebAppPenTestForm = () => {
  const [webAppCount, setWebAppCount] = useState(1);
  const [webAppSize, setWebAppSize] = useState('Small');
  const [includeAdditionalServices, setIncludeAdditionalServices] = useState(false);
  const [containsSensitiveData, setContainsSensitiveData] = useState(false); // New state variable
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);

  const baseRate = 275; // Example base rate per hour

  // Placeholder for a more complex estimation logic
  const calculateEstimate = () => {
    let hours = 0;

    //Estimate hours based on the size of the web app
    switch (webAppSize) {
      case 'Small - Single Purpose':
        hours = 24;
        break;
      case 'Medium - Business Application':
        hours = 48;
        break;
      case 'Large - Enterprise Application':
        hours = 80;
        break;
      default:
        hours = 24;
        break;
    }

    // Multiply by number of web apps
    hours *= webAppCount;

    // Increase by 20% if additional services are included
    if (includeAdditionalServices) {
      hours *= 1.2;
    }

    // Apply complex/sensitive data multiplier
    if (containsSensitiveData) {
      hours *= 1.2;
    }

    // Calculate final estimated price
    const estimate = hours * baseRate;
    setEstimatedPrice(estimate);
  };

  return (
    <div style={{ marginTop: '20px', margin: '20px' }}>
      <NumberInput
        label="Number of web applications"
        description="Enter the number of web applications for penetration testing"
        placeholder="e.g., 3"
        min={1}
        value={webAppCount}
        onChange={setWebAppCount}
      />

      <Select
        label="Size of web applications"
        placeholder="Select size"
        data={['Small - Single Purpose', 'Medium - Business Application', 'Large - Enterprise Application']}
        value={webAppSize}
        onChange={setWebAppSize}
      />

      <Switch
        label="Include additional services"
        description="Select if you want to include additional services like manual code review, configuration checks, etc."
        checked={includeAdditionalServices}
        onChange={(event) => setIncludeAdditionalServices(event.currentTarget.checked)}
        style={{ marginTop: '20px', marginBottom: '10px' }}
      />

      <Switch
        label="Complex or contains sensitive data"
        checked={containsSensitiveData}
        onChange={(event) => setContainsSensitiveData(event.currentTarget.checked)}
        tyle={{ marginTop: '20px', marginBottom: '20px' }} 
      />

      <Group spacing="md" style={{ marginTop: '20px', margin: '5px', marginBottom: '10px' }}>
        <Button onClick={calculateEstimate}>Calculate</Button>
        <Button variant="outline" onClick={() => setModalOpen(true)}>Request Quote</Button>
      </Group>

        <Text size="lg" weight={500}>
          Estimated Price: ${(.80 * estimatedPrice).toLocaleString()} - ${(1.4 * estimatedPrice).toLocaleString()}
        </Text>

      <QuoteRequestModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        formData={{ webAppCount, webAppSize, includeAdditionalServices, estimatedPrice }}
      />
    </div>
  );
};

export default WebAppPenTestForm;
