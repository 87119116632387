import React, { useState } from 'react';
import { NumberInput, Select, Button, Group, Text } from '@mantine/core';
import QuoteRequestModal from './QuoteRequestModal';


const NetworkPenTestForm = () => {
    const [deviceCount, setDeviceCount] = useState(10);
    const [networkComplexity, setNetworkComplexity] = useState('Low');
    const [estimatedPrice, setEstimatedPrice] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);


    const calculateEstimate = () => {
        // Base price per device decreases as the number of devices increases
        const basePrice = 50 * Math.pow(0.9, Math.log(deviceCount));

        let complexityMultiplier;
        switch (networkComplexity) {
            case 'Low':
                complexityMultiplier = 1;
                break;
            case 'Medium':
                complexityMultiplier = 1.2;
                break;
            case 'High':
                complexityMultiplier = 1.5;
                break;
            default:
                complexityMultiplier = 1;
                break;
        }

        let price = deviceCount * basePrice * complexityMultiplier;
        // Minimum price floor
        price = Math.max(2500, price);
        // Maximum reasonable ceiling
        price = Math.min(60000, price);

        // Round up to the nearest $100
        price = Math.ceil(price / 100) * 100;

        setEstimatedPrice(price);
    };

    return (
        <div style={{ marginTop: '20px', margin: '20px' }}>
        <NumberInput
                label="Number of devices in the network"
                description="Enter the total number of devices that require testing"
                placeholder="e.g., 50"
                min={1}
                value={deviceCount}
                onChange={setDeviceCount}
            />

            <Select
                label="Network Complexity"
                placeholder="Select complexity"
                data={['Low', 'Medium', 'High']}
                value={networkComplexity}
                onChange={setNetworkComplexity}
            />

            <Group spacing="md" style={{ marginTop: '20px', margin: '5px' }}>
                <Button onClick={calculateEstimate}>Calculate</Button>
                <Button variant="outline" onClick={() => setModalOpen(true)}>Request Quote</Button>
            </Group>

            <Text size="lg" weight={500}>
            Estimated Price: ${(.90 * estimatedPrice).toLocaleString()} - ${(1.3 * estimatedPrice).toLocaleString()}
            </Text>

            <QuoteRequestModal
                isModalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                formData={{ deviceCount, networkComplexity, estimatedPrice }}
            />
        </div>
    );
};

export default NetworkPenTestForm;
